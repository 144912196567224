module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"aZOV48RybUgaaHIHJQ3J0oosmJQu4eDd","devKey":"bAf0GJmmfepvZh3MNF9MnxQqlo2z84Ur","trackPage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_lWjOk5axbYExgvN8Bzs9LbPhGd86f26nNPgreYsVVjm","apiHost":"https://ph.mangomint.com","ui_host":"https://us.posthog.com","head":true,"isEnabledDevMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mangomint.com","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
